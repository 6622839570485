import React from 'react';
import styled from 'styled-components';
import {
  PinterestShareButton,
  PinterestShareCount,
  PinterestIcon
} from 'react-share';

const Button = styled.div``;

const StyledPinterestIcon = styled(PinterestIcon)`
  border-radius: 4px;
`;

const StyledPinterestShareCount = styled(PinterestShareCount)`
  font-family: ${props => props.theme.headerFontFamily};
`;

const Pinterest = ({ url, originalUrl, image, size, description }) => (
  <Button>
    <PinterestShareButton url={url} media={image} description={description}>
      <StyledPinterestIcon size={size} />
    </PinterestShareButton>

    <StyledPinterestShareCount url={originalUrl}>
      {count => count}
    </StyledPinterestShareCount>
  </Button>
);

const TaggedPinterest = ({ url, ...rest }) => {
  const taggedUrl = `${url}?utm_source=pinterest&utm_medium=post&utm_campaign=share`;
  return <Pinterest url={taggedUrl} originalUrl={url} {...rest} />;
};

export default TaggedPinterest;
