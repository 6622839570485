import React from 'react';
import styled from 'styled-components';
import getSections from '../../utils/sections';
import { media } from '../../utils/styles';

const Wrapper = styled.div`
  clear: both;
  box-sizing: border-box;
  display: inline-block;
  width: 100%;
`;

const LeftPane = styled.div`
  width: 100%;
  padding: 0;

  ${media.tablet`
    width: 50%;
    float: left;
    padding: 0 20px 0 0;
  `};
`;

const RightPane = styled.div`
  width: 100%;
  padding: 20px 0 0;

  ${media.tablet`
    width: 50%;
    padding: 0 0 20px 0;
    float: left;
  `};
`;

export default ({ leftSections, rightSections, page, url }) => {
  const leftSectionComponents = getSections(leftSections, url, page, true);
  const rightSectionComponents = getSections(rightSections, url, page, true);
  return <Wrapper>
      <LeftPane key="left">{leftSectionComponents}</LeftPane>
      <RightPane key="right">{rightSectionComponents}</RightPane>
    </Wrapper>;
}
