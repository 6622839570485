import React from 'react';
import styled from 'styled-components';
import Tick from '../../images/tick.svg';

const Wrapper = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
`;

const Title = styled.h2`
    font-family: 'proxima-soft',Helvetica,Arial,sans-serif;
    font-weight: 300;
    max-width: 480px;
    letter-spacing: 0.5px;
    margin: 0;
    font-size: 40px;
    text-align: center;
    color: #454D57;
    margin-bottom: 20px;
    white-space: pre-wrap;
    margin: 40px 0 20px 0;
    line-height: 1.17;

    @media screen and (max-width: 940px) {
        font-size: 28px;
    }
`;

const CollapseWrapper = styled.div`
    width: 100%;
    max-width: 950px;
`;

const CollapseBox = styled.div`
    margin-bottom: 5px;
    border: 1px solid rgba(158,171,188,0.70);
    border-radius: 4px;
    font-family: 'proxima-soft',Helvetica,Arial,sans-serif;
    user-select: none;
    overflow: hidden;

    @media screen and (max-width: 940px) {
        margin-bottom: 10px;
    }
`;

const Question = styled.div`
    padding: 18px 60px 18px 20px;
    position: relative;
    font-size: 18px;
    color: ${props => props.color};
    cursor: pointer;
    transition: color 0.3s linear;

    &:hover {
        color: #EA3154;
        transition: color 0.3s linear;
    }

    @media screen and (max-width: 940px) {
        padding: 15px 60px 15px 15px;
        font-size: 13px;
    }
`;

const Answer = styled.div`
    overflow: hidden;
    max-height: 0;
    background-color: #F8F9FC;
    color: #748294;
    padding: 0 20px;
    transition: all .125s linear;

    &.opened {
        max-height: 150px;
        padding: 10px 20px;
    }

    @media screen and (max-width: 940px) {
        padding: ${props => props.height ? '10px 15px' : '0'};
        font-size: 13px;
    }
`;

const TickIcon = styled.div`
    width: 30px;
    height: 30px;
    background-image: url(${Tick});
    position: absolute;
    right: 15px;
    top: 0;
    bottom: 0;
    margin: auto;
    transform: rotate(${props => props.angle});
    transition: all .125s linear;

    @media screen and (max-width: 940px) {
        width: 25px;
        height: 25px;
        background-size: 100%;
    }

    
`;
class Collapse extends React.Component {
    constructor(props) {
        super(props);
        this.state = { collapseIsOpen: false }
    }
    
    toggleCollapse = () => this.setState({collapseIsOpen: !this.state.collapseIsOpen});
    
    render() {
        const {question: {question, answer}} = this.props;
        const color = this.state.collapseIsOpen ? '#EA3154' : '#748294';
        const angle = this.state.collapseIsOpen ? '180deg' : '0deg'

        return (
            <CollapseBox>
                <Question color={color} onClick={this.toggleCollapse}>
                    {question.question}
                    <TickIcon angle={angle} />
                </Question>
                <Answer className={this.state.collapseIsOpen && 'opened'}>{answer.answer}</Answer>
            </CollapseBox>
        )
    }
}

export default ({section: {title, questions = []}}) => {
    return (
        <Wrapper>
            <Title>{title.replace('/n', `\n`)}</Title>
            <CollapseWrapper>
                {questions.map(question => <Collapse key={question.id} question={question} />)}
            </CollapseWrapper>
        </Wrapper>
    )
}