import React from "react";
import styled from 'styled-components';

const Section = styled.section`
    display: flex;
    justify-content: center;
    font-family: 'proxima-soft',Helvetica,Arial,sans-serif;
    background-color: #F8F9FC;
    margin: 50px 0 0 0;
`;

const Wrapper = styled.div`
    width: 100%;
    max-width: 1140px;
    padding: 50px 20px 40px 20px;
    position: relative;

    @media (max-width: 850px) {
        padding: 50px 20px 10px 20px;
    }
`;

const RadioBox = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    bottom: auto;
    top: -30px;
    margin: auto;
    height: 58px;
    width: 400px;
    border-radius: 30px;
    background-color: #EA3154;
    display: flex;
    justify-content: space-between;

    @media (max-width: 430px) {
        width: 280px;
        height: 48px;
        top: -23px;
    }

    @media (max-width: 350px) {
        width: 280px;
    }
`;

const RadioLabel = styled.label`
    width: 50%;
    height: 100%;
    display: flex;
    font-size: 30px;
    color: rgba(255, 255, 255, .7);
    font-weight: 100;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;

    & input {
        display: none;
    }

    @media (max-width: 430px) {
        font-size: 25px;
    }
`;

const Selected = styled.div`
    position: absolute;
    width: 200px;
    height: 60px;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.15);
    background-color: #FFF;
    color: #EA3154;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    bottom: -1px;
    left: ${props => props.value === 'When' ? '-2px' : '201px'};
    transition: all ease 0.1s;

    @media (max-width: 430px) {
        width: 140px;
        left: ${props => props.value === 'When' ? '-2px' : '161px'};
        font-size: 25px;
        height: 50px;
    }

    @media (max-width: 350px) {
        width: 140px;
        left: ${props => props.value === 'When' ? '-2px' : '141px'};
    }
`;

const Description = styled.div`
    position: ${props => props.display ? 'static' : 'absolute'};
    visibility: ${props => props.display ? 'visible' : 'hidden'};

    & .when_section, & .how_section {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;

        @media (max-width: 850px) {
            flex-direction: column;
        }

        .section_image {
            width: 50%;
            max-width: 440px;
            background-repeat: no-repeat;
            background-size: cover;
            height: 415px;
            margin-right: 40px;
            display: flex;
            flex-shrink: 0;
            background-position: center;

            @media (max-width: 850px) {
                width: 100%;
                max-width: none;
            }
        }

        & h2 {
            color: #454D57;
            font-size: 30px;
            font-weight: 300;

            @media (max-width: 850px) {
                font-size: 24px;
            }
        }

        & p {
            color: #748294;
            font-size: 20px;
            font-weight: 300;
            line-height: 28px;

            @media (max-width: 850px) {
                font-size: 18px;
            }
        }
    }
`;

export default ({ section, radioOnChange, radioValue }) => {
    const data = {
        options: [{ value: section.option1Key }, { value: section.option2Key }],
        values: [{ html: section.option1Field.childMarkdownRemark.html, id: section.option1Key }, { html: section.option2Field.childMarkdownRemark.html, id: section.option2Key }]
    }

    return (
        <Section>
            <Wrapper>
                <RadioBox>
                    {data.options.map((option, i) => (
                        <RadioLabel key={option.value}>
                            <input 
                                checked={option.value === radioValue} 
                                onChange={(e) => radioOnChange(e.target.value)} 
                                value={option.value} 
                                name="options" 
                                id={option.value} 
                                type="radio" 
                            />
                            {option.value}
                        </RadioLabel>
                    ))}
                    <Selected value={radioValue}>{radioValue}</Selected>
                </RadioBox>
                {
                    data.values.map((value) => (
                        <Description 
                            key={value.id} 
                            display={value.id === radioValue ? 1 : 0} 
                            dangerouslySetInnerHTML={{ __html: value.html }} 
                        />
                    ))
                }
            </Wrapper>
        </Section>
    )
}