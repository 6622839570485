import React from 'react';
import styled from 'styled-components';
import {
  WhatsappShareButton,
  WhatsappIcon,
} from 'react-share';

const Button = styled.div`
`;

const StyledWhatsappIcon = styled(WhatsappIcon)`
  border-radius: 4px;
`;

const Whatsapp = ({ url, title, size }) => (
  <Button>
    <WhatsappShareButton
      url={url}
      title={title}
      separator=" - ">
      <StyledWhatsappIcon size={size} />
    </WhatsappShareButton>
  </Button>
);

const TaggedWhatsapp = ({ url, ...rest }) => {
  const taggedUrl = `${url}?utm_source=whatsapp&utm_medium=post&utm_campaign=share`;
  return <Whatsapp url={taggedUrl} {...rest} />
}

export default TaggedWhatsapp;
