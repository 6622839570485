import React from 'react';
import styled from 'styled-components';
import {
  FacebookShareButton,
  FacebookShareCount,
  FacebookIcon
} from 'react-share';

const Button = styled.div``;

const StyledFacebookIcon = styled(FacebookIcon)`
  border-radius: 4px;
`;

const StyledFacebookShareCount = styled(FacebookShareCount)`
  font-family: ${props => props.theme.headerFontFamily};
`;

const Facebook = ({ url, originalUrl, title, size }) => (
  <Button>
    <FacebookShareButton url={url} quote={title}>
      <StyledFacebookIcon size={size} />
    </FacebookShareButton>

    <StyledFacebookShareCount url={originalUrl}>
      {count => count}
    </StyledFacebookShareCount>
  </Button>
);

const TaggedFacebook = ({ url, ...rest }) => {
  const taggedUrl = `${url}?utm_source=facebook&utm_medium=post&utm_campaign=share`;
  return <Facebook url={taggedUrl} originalUrl={url} {...rest} />;
};

export default TaggedFacebook;
