import styled from 'styled-components';
import { media } from '../utils/styles';

export default styled.div`
  margin: 0 auto;
  text-align: ${props => props.centerText ? 'center' : 'left'};

  ${media.tablet`
    margin: 0 auto;
  `}
`;
