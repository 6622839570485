import styled from 'styled-components';
import { media } from '../utils/styles';

export default styled.div`
  padding: 0 15px;
  margin: 0 auto;

  ${media.tablet`
    max-width: 750px;
  `}

  ${media.smallDesktop`
    max-width: 900px;
  `}

  ${media.desktop`
    max-width: 970px;
  `}

  ${media.giant`
    max-width: 1183px;
  `}
`;
