import React from 'react';
import Img from 'gatsby-image';
import styled from 'styled-components';
import Link from '../Link';

const StyledImage = styled(Img)`
  border-radius: 4px;
  margin: 10px auto;
  width: 80%;
`;

export default ({ section }) => {
  return section.url ? <Link to={section.url}>
      <StyledImage fluid={section.imageSource.fluid} alt={section.imageSource.title} />
    </Link> : <StyledImage fluid={section.imageSource.fluid} alt={section.imageSource.title} />;
}
