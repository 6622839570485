import React from 'react';
import styled from 'styled-components';
import { media } from '../../utils/styles';
import Facebook from './Facebook';
import Twitter from './Twitter';
import Pinterest from './Pinterest';
import Email from './Email';
import Whatsapp from './Whatsapp';

const ICON_SIZE = 40;

const ButtonsWrapper = styled.div`
  font-family: ${props => props.theme.headerFontFamily};
  text-align: center;
  padding: 20px 0 0;
`;

const Title = styled.p`
  text-transform: uppercase;
  font-size: 20px;
  margin: 0 0 5px 0;
  font-weight: 600;
  color: #656565;
`;

const Buttons = styled.ul`
  list-style-type: none;
  margin: 0 auto;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  max-width: 290px;
  ${media.tablet`
    max-width: 250px;
  `}
`;

const Button = styled.li`
  width: ${ICON_SIZE}px;
  height: 70px;
  text-align: center;
  cursor: pointer;
`;

const MobileOnlyButton = styled(Button)`
  ${media.tablet`
    display: none;
  `}
`;

export default ({ url, title, image, description }) => (
  <ButtonsWrapper>
    <Title>Share this</Title>
    <Buttons>
      <Button>
        <Facebook url={url} title={title} size={ICON_SIZE} />
      </Button>
      <Button>
        <Twitter url={url} title={title} size={ICON_SIZE} />
      </Button>
      {image &&
        <Button>
          <Pinterest url={url} size={ICON_SIZE} image={image} description={description} />
        </Button>
      }
      <Button>
        <Email url={url} title={title} size={ICON_SIZE} description={description} />
      </Button>
      <MobileOnlyButton>
        <Whatsapp url={url} title={title} size={ICON_SIZE} />
      </MobileOnlyButton>
    </Buttons>
  </ButtonsWrapper>
);
