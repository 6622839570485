import React from 'react';
import Content from '../components/SectionContent';
import Image from '../components/SectionImage';
import FAQSection from '../components/FAQSection';
import Section2Pane from '../components/Section2Pane';
import SectionWrapper from '../elements/SectionWrapper';
import ShareButtons from '../components/ShareButtons';
import Testimonials from '../components/Testimonials';
import Radio2Options from '../components/Radio2Options';
import ImagesCarousel from '../components/ImagesCarousel';

const getSectionList = sections => {
  let contentSections = [];
  if (Array.isArray(sections)) {
    sections.forEach(section => {
      if (section === null) {
        return null;
      }
      if (section.__typename === 'ContentfulSectionContent') {
        contentSections.push(section);
      }
      if (section.__typename === 'ContentfulSectionImage') {
        contentSections.push(section);
      }
    });
  } else {
    if (sections === null) {
      return null;
    }
    if (sections.__typename === 'ContentfulSectionFullWidth') {
      contentSections = contentSections.concat(getSectionList(sections.sections));
    }
    if (sections.__typename === 'ContentfulSectionContent') {
      contentSections.push(sections);
    }
    if (sections.__typename === 'ContentfulSectionImage') {
      contentSections.push(sections);
    }
    if (sections.__typename === 'ContentfulSection2Pane') {
      contentSections = contentSections.concat(getSectionList(sections.leftSections));
      contentSections = contentSections.concat(getSectionList(sections.rightSections));
    }
  }
  return contentSections;
}

const getImageSections = sections => {
  return sections.filter(section => {
    return section.__typename === 'ContentfulSectionImage';
  });
};

const extractImages = sections => {
  const imageSectionImages = sections.map(newSection => {
    return newSection.imageSource.fluid.src;
  });
  if (imageSectionImages.length > 0) {
    return imageSectionImages[0];
  } else {
    return null;
  }
}

const extractHtml = sections => {
  let totalHtml = ``;
  sections.forEach(section => {
    if (section.__typename === 'ContentfulSectionContent') {
      const html = section.content.childMarkdownRemark.html;
      totalHtml += html;
    }
  });
  const re = /<img[^>]+src="([^">]+)/g;
  const results = re.exec(totalHtml);
  if (results !== null) {
    const source = results[1];
    return source;
  } else {
    return null;
  }
}

export const getImage = sections => {
  const images = sections.map(section => {
    const contentSections = getSectionList(section);
    const imageSections = getImageSections(contentSections);
    if (imageSections.length > 0) {
      return extractImages(imageSections);
    } else {
      return extractHtml(contentSections);
    }
  });
  if (images === null && images.length === 0) {
    return null;
  }
  return images[0];
};

const getComponents = (section, url, page, wrap) => {
  if (section.__typename === 'ContentfulSection2OptionRadio') {
    return <Radio2Options section={section} key={`wrapper_${section.id}`} />
  }
  if (section.__typename === 'ContentfulSectionCarousel') {
    return <ImagesCarousel section={section} key={`wrapper_${section.id}`} />
  }
  if (section.__typename === 'ContentfulSectionTestimonials') {
    return <Testimonials section={section} key={`wrapper_${section.id}`} />
  }
  if (section.__typename === 'ContentfulSectionFaq') {
    return <FAQSection key={`wrapper_${section.id}`} section={section} />
  }
  if (section.__typename === 'ContentfulSectionFullWidth') {
    return section.sections.map(section => {
      return getComponents(section, url, page, false);
    });
  }
  if (section.__typename === 'ContentfulSectionContent') {
    return wrap ? (
      <SectionWrapper key={`wrapper_${section.id}`}>
        <Content section={section} />
      </SectionWrapper>
    ) : (
      <Content key={`content_${section.id}`} section={section} />
    );
  }
  if (section.__typename === 'ContentfulSectionImage') {
    return wrap ? (
      <SectionWrapper key={`wrapper_${section.id}`}>
        <Image section={section} />
      </SectionWrapper>
    ) : (
      <Image key={`image_${section.id}`} section={section} />
    );
  }
  if (section.__typename === 'ContentfulSection2Pane') {
    return (
      <SectionWrapper key={`wrapper_${section.id}`}>
        <Section2Pane
          leftSections={section.leftSections}
          rightSections={section.rightSections}
          page={page}
          url={url}
        />
      </SectionWrapper>
    );
  }
  if (section.__typename === 'ContentfulSectionShareButtons') {
    const image = getImage(page.sections);
    const description = page.pageMetaData.description;
    const title = page.title;
    return <ShareButtons key={`share_buttons`} title={title} image={image} url={url} description={description} />;
  }
  return null;
};

export default (sections, url, page, wrap) => {
 return sections.map(section => {
    return getComponents(section, url, page, wrap);
  });
};
