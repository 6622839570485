import React from 'react';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

const getMetaTags = ({
  url, title, description, author, publishedAt, imageSrc, tags, contentType, facebookUrl, twitter, facebookAppId
}) => {
  const metaTags = [
    { itemprop: 'name', content: title },
    { itemprop: 'description', content: description },
    { name: 'description', content: description },
    { name: 'twitter:site:id', content: twitter },
    { name: 'twitter:title', content: title },
    { name: 'twitter:description', content: description },
    { name: 'twitter:creator', content: `@${twitter}` || '@nubabiapp' },
    { property: 'og:title', content: title },
    { property: 'og:type', content: contentType },
    { property: 'og:url', content: url },
    { property: 'og:description', content: description },
    { property: 'og:site_name', content: 'Nubabi' },
    { property: 'fb:app_id', content: facebookAppId },
    { name: 'p:domain_verify', content: 'bd2f1bf9d43be5192d2b9c0adbd338e6' }
  ]

  if (publishedAt) metaTags.push({ name: 'article:published_time', content: publishedAt });
  if (tags) tags.map(tag => metaTags.push({ name: 'article:tag', content: tag.name }));
  if (imageSrc) {
    let updatedImageSrc = imageSrc;
    if (!(imageSrc.startsWith('http:') || imageSrc.startsWith('https:'))) {
      updatedImageSrc = `https:${imageSrc}`;
    }
    metaTags.push({ itemprop: 'image', content: updatedImageSrc });
    metaTags.push({ name: 'twitter:image:src', content: updatedImageSrc });
    metaTags.push({ name: 'twitter:card', content: 'summary_large_image' });
    metaTags.push({ property: 'og:image', content: updatedImageSrc });
    metaTags.push({ itemprop: 'image', content: updatedImageSrc });
  }
  if (process.env.GATSBY_ENABLE_ROBOTS && process.env.GATSBY_ENABLE_ROBOTS === 'true') {
    metaTags.push({ name: 'robots', content: 'all' });
  } else {
    metaTags.push({ name: 'robots', content: 'noindex, nofollow' });
  }

  return metaTags;
}

const getSchema = ({
  schema,
  title,
  description,
  url,
  author,
  publishedAt,
  imageSrc,
}) => {
  if (schema === undefined) return {};
  const returnSchema = {
    '@context': 'http://schema.org',
    '@type': schema,
    headline: title,
    publisher: {
      '@type': 'Organization',
      name: 'Nubabi',
      logo: {
        '@type': 'ImageObject',
        url: 'https://firebasestorage.googleapis.com/v0/b/nubabitest1.appspot.com/o/logo%2Fnubabi_logo.png?alt=media&token=8ec18ec3-bf49-49d8-afea-fd798f5f38ee'
      }
    },
    description,
  };
  if (imageSrc) {
    returnSchema.image = {
      '@type': 'ImageObject',
      url: imageSrc
    }
  }
  if (author) {
    returnSchema.author = {
      '@type': 'Person',
      name: author,
    }
  }
  if (publishedAt) {
    returnSchema.datePublished = publishedAt;
  }
  return {
    type: 'application/ld+json',
    innerHTML: JSON.stringify(returnSchema),
  };
}

export default ({
  schema,
  title,
  description,
  url,
  author,
  contentType,
  publishedAt,
  imageSrc,
  tags,
}) => (
    <StaticQuery
      query={graphql`
        query {
          contentfulSiteConfig {
            facebookUrl
            twitterHandle
            facebookAppId
          }
        }
      `}
      render={data => {
        const { contentfulSiteConfig:
          {
            twitterHandle, facebookUrl, facebookAppId
          }
        } = data;
        const links = [];
        if (url) {
          links.push({ rel: 'canonical', href: url });
        }
        return <Helmet
          htmlAttributes={{ lang: 'en' }}
          title={title}
          link={links}
          script={[getSchema(
                {
                  schema,
                  title,
                  description,
                  url,
                  author,
                  imageSrc,
                  publishedAt
                }
              )]}
          meta={getMetaTags({
            title,
            description,
            url,
            author,
            contentType,
            publishedAt,
            tags,
            twitter: twitterHandle,
            imageSrc,
            facebookUrl,
            facebookAppId,
        })}
      />;}
    }
  />
  );
