import React from 'react';
import styled from 'styled-components';
import {
  EmailShareButton,
  EmailIcon,
} from 'react-share';

const Button = styled.div``;

const StyledEmailIcon = styled(EmailIcon)`
  border-radius: 4px;
`;

const Email = ({ url, title, size, description }) => (
  <Button>
    <EmailShareButton
      url={url}
      subject={title}
      body={`Hi!\n\nLook what I found from Nubabi!\n\n${description} - ${url}\n\n`}>
      <StyledEmailIcon size={size} />
    </EmailShareButton>
  </Button>
);

const TaggedEmail = ({ url, ...rest }) => {
  const taggedUrl = `${url}?utm_source=email&utm_medium=post&utm_campaign=share`;
  return <Email url={taggedUrl} {...rest} />
}

export default TaggedEmail;
