import React from 'react';
import Layout from '../components/Layout';
import ContentTemplate from '../components/ContentTemplate';
import { graphql } from 'gatsby';
import Seo from '../components/Seo';

export default ({ data: { contentfulPage: page }, location: { href } }) => {
  return <Layout canonicalUrl={href}>
    <Seo schema={'Article'} contentType={'article'} title={page.title} url={href} description={page.pageMetaData.description} />
    <ContentTemplate page={page} url={href} />
  </Layout>;
};

export const contentQuery = graphql`
  query ContentQuery($id: String!) {
    contentfulPage(id: { eq: $id }) {
      id
      title
      slug
      displayTitle
      pageType {
        name
      }
      pageMetaData {
        title
        description
      }
      sections {
        __typename
        ... on ContentfulSectionCarousel {
          id
          name
        }
        __typename
        ... on ContentfulSectionTestimonials {
          id
          name
          title
        }
        __typename
        ... on ContentfulSectionFaq {
          id
          name
          title
          questions {
            id
            question {
              question
            }
            answer {
              answer
            }
          }
        }
        __typename
        ... on ContentfulSectionContent {
          id
          content {
            childMarkdownRemark {
              html
            }
          }
          css {
            css
          }
        }
        __typename
        ... on ContentfulSectionFullWidth {
          id
          sections {
            __typename
            ... on ContentfulSectionCarousel {
              id
              name
              images {
                id
                description
                file {
                  url
                }
              }
            }
            __typename
            ... on ContentfulSection2OptionRadio {
              id
              option1Key
              option2Key
              option1Field {
                id
                childMarkdownRemark {
                  html
                }
              }
              option2Field {
                id
                childMarkdownRemark {
                  html
                }
              }
            }
            __typename
            ... on ContentfulSectionTestimonials {
              id
              name
              title
              testimonials {
                id
                avatar {
                  id
                  title
                  description
                  fixed(width: 250, quality: 75) {
                    src
                  }
                  file {
                    url
                  }
                }
                testimonial {
                  id
                  testimonial
                }

              }
            }
            __typename
            ... on ContentfulSectionFaq {
              id
              name
              title
              questions {
                id
                question {
                  question
                }
                answer {
                  answer
                }
              }
            }
            __typename
            ... on ContentfulSectionContent {
              id
              content {
                childMarkdownRemark {
                  html
                }
              }
              css {
                css
              }
            }
            ... on ContentfulSectionImage {
              id
              url
              imageSource {
                title
                fluid(maxWidth: 1000) {
                  ...GatsbyContentfulFluid
                }
              }
            }
          }
        }
        ... on ContentfulSectionImage {
          id
          url
          imageSource {
            title
            fluid(maxWidth: 1000) {
              ...GatsbyContentfulFluid
            }
          }
        }
        ... on ContentfulSection2Pane {
          id
          leftSections {
            __typename
            ... on ContentfulSectionContent {
              id
              content {
                childMarkdownRemark {
                  html
                }
              }
              css {
                css
              }
            }
            ... on ContentfulSectionImage {
              id
              url
              imageSource {
                title
                fluid(maxWidth: 1000) {
                  ...GatsbyContentfulFluid
                }
              }
            }
          }
          rightSections {
            __typename
            ... on ContentfulSectionContent {
              id
              content {
                childMarkdownRemark {
                  html
                }
              }
              css {
                css
              }
            }
            ... on ContentfulSectionImage {
              id
              url
              imageSource {
                title
                fluid(maxWidth: 1000) {
                  ...GatsbyContentfulFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
