import React from 'react';
import { Helmet } from 'react-helmet';
import Content from '../Content';

export default ({ section: { content, css } }) => {
  const html = content.childMarkdownRemark.html;
  let head = null;
  if (css !== null && css !== undefined) {
    head = <Helmet>
        <style type="text/css">{css.css}</style>
      </Helmet>;
  }
  return (
    <>
      {head}
      <Content content={html} />
    </>
  );
};
