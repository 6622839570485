import { compose, lifecycle, withState, withHandlers } from 'recompose';
import Testimonials from './Testimonials';

export default compose(
    withState('slidesToShow', 'updateSlidesCount', 3),
    withHandlers({
        updateCount: ({ updateSlidesCount }) => () => {
            const windowWidth = window && window.innerWidth;

            if (windowWidth) {
                if (windowWidth < 660) {
                    return updateSlidesCount(1)
                } else if (windowWidth < 940) {
                    return updateSlidesCount(2)
                } else {
                    return updateSlidesCount(3)
                }
            }
        }
    }),
    lifecycle({
        componentDidMount() {
            this.props.updateCount()
            window.addEventListener("resize", this.props.updateCount);
        },
        componentWillUnmount() {
            window.removeEventListener("resize", this.props.updateCount);
        }
    })
)(Testimonials)