import React from 'react';
import styled from 'styled-components';
import {
  TwitterShareButton,
  TwitterIcon,
} from 'react-share';

const Button = styled.div``;

const StyledTwitterIcon = styled(TwitterIcon)`
  border-radius: 4px;
`;

const Twitter = ({ url, originalUrl, title, size }) => (
  <Button>
    <TwitterShareButton url={url} title={`${title} -`} via={`NubabiApp`}>
      <StyledTwitterIcon size={size} />
    </TwitterShareButton>
  </Button>
);

const TaggedTwitter = ({ url, ...rest }) => {
  const taggedUrl = `${url}?utm_source=twitter&utm_medium=post&utm_campaign=share`;
  return <Twitter url={taggedUrl} originalUrl={url} {...rest} />;
};

export default TaggedTwitter;
