import React from 'react';
import styled from 'styled-components';
import Carousel from 'nuka-carousel';
import Arrow from '../../images/arrow.svg';

const Container = styled.section`
    background-color: #F8F9FC;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
`;

const Title = styled.h2`
    font-family: 'proxima-soft',Helvetica,Arial,sans-serif;
    font-weight: 300;
    max-width: 480px;
    letter-spacing: 0.5px;
    margin: 0;
    font-size: 40px;
    text-align: center;
    color: #454D57;
    margin-bottom: 20px;
    white-space: pre-wrap;
    margin: 40px 0 20px 0;
    line-height: 1.17;

    @media (max-width: 940px) {
        font-size: 28px;
        max-width: 380px;
        padding: 0 20px;
    }
`;

const ImageSlide = styled.div`
    display: flex;
    justify-content: center;
    height: 540px;

    & img {
        display: block;
        border-radius: 11px;
        max-width: 100%;
        max-height: 100%;
    }
`;

const SliderWrapper = styled.div`
    display: flex;
    width: 100%;
    padding: 0 50px 80px 50px;
    max-width: 1170px;

    & .slider-frame {
        padding-bottom: 3px !important;
    }

    & .slider-control-bottomcenter {
        bottom: -45px !important;

        @media (max-width: 730px) {
            bottom: -35px !important;
        }
    }

    @media (max-width: 730px) {
        padding: 0 15px 60px 15px;
    }
`;

const LeftButton = styled.div`
    width: 23px;
    height: 37px;
    background-image: url(${Arrow});
    position: relative;
    cursor: pointer;
    left: -30px;
    top: -22px;

    &.disabled {
        cursor: default;
        opacity: 0.5;
    }

    @media all and (max-width: 730px) {
        display: none;
    }
`;

const RightButton = styled(LeftButton)`
    transform: rotate(180deg);
    left: 30px;
`;

const IndicatorArray = styled.div`
    display: flex;
`;

const Indicator = styled.div`
    background-color: #EA3154;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    opacity: 0.5;
    margin: 0 5px;
    cursor: pointer;

    &:hover {
        opacity: 0.75;
    }

    &.current {
        opacity: 1;
    }

`;

const countIndicators = ({ slideCount, slidesToShow }) => { 
    if (slideCount % slidesToShow === 0) {
        return slideCount / slidesToShow
    }

    return Math.floor(slideCount / slidesToShow) + 1
}

const indicatorClassName = ({ currentSlide, index, slidesToScroll, slideCount, slidesToShow }) => {
    if (slideCount % slidesToShow === 0) {
        return currentSlide / slidesToScroll === index && 'current'
    }

    return Math.ceil(currentSlide / slidesToScroll) === index && 'current'
} 

const renderLeftButton = ({ previousSlide, currentSlide }) => <LeftButton className={!currentSlide && 'disabled'} onClick={previousSlide} />;

const renderRightButton = ({ nextSlide, currentSlide, slideCount, slidesToShow }) => {
    let cn = '';

    if (slideCount % slidesToShow === 0) {
        if (currentSlide >= (slideCount / slidesToShow)) {
            cn = 'disabled';
        }
    } else {
        if (currentSlide >= Math.ceil(slideCount / slidesToShow)) {
            cn = 'disabled'
        }
    }

    return <RightButton className={cn} onClick={nextSlide} />
};

const renderIndicator = ({ currentSlide, slideCount, goToSlide, slidesToShow, slidesToScroll }) => {
    return (
        <IndicatorArray>
            {Array.apply(null, Array(countIndicators({ slideCount, slidesToShow }))).map((item, index) => (
                <Indicator
                    key={`key-${index}`}
                    onClick={() => goToSlide(index * slidesToScroll)}
                    className={`${indicatorClassName({ currentSlide, index, slidesToScroll, slideCount, slidesToShow })}`}
                />
            ))}
        </IndicatorArray>
    )
};

const renderImage = (image) => (
    <ImageSlide key={image.id}>
        <img alt={image.description} src={image.file.url} />
    </ImageSlide>
)

export default ({ slidesToShow, section: {images, name} }) => {
    return (
        <Container>
             <Title>{name}</Title>
            <SliderWrapper>
                <Carousel
                    slidesToScroll={slidesToShow}
                    renderCenterLeftControls={renderLeftButton}
                    renderCenterRightControls={renderRightButton}
                    renderBottomCenterControls={(centerControlsProps) => renderIndicator({ ...centerControlsProps, slidesToShow })}
                    slidesToShow={slidesToShow}
                    heightMode={'max'}
                >
                    {images.map(renderImage)}
                </Carousel>
            </SliderWrapper>
        </Container>
    )
}
