import React from 'react';
import styled from 'styled-components';
import { media } from '../../utils/styles';

const Body = styled.div`
  font: 300 21px/1.58 ${props => props.theme.sansSerifFontFamily};
  text-rendering: optimizeLegibility;
  color: rgba(0, 0, 0, 0.84);
  letter-spacing: -0.003em;
  text-transform: none;
  font-style: normal;
  --x-height-multiplier: 0.375;
  --baseline-multiplier: 0.17;

  h2,
  h3,
  h4,
  h5 {
    color: #656565;

    + p {
      margin-top: 20px;
    }
  }

  h2 {
    font-size: 28px;
    margin-left: -1.75px;
    line-height: 1.22;
    letter-spacing: -0.012em;
  }

  h3 {
    font-weight: 600;
    margin-top: 20px;
    line-height: 1.22;
    letter-spacing: -0.012em;
  }

  p {
    margin-top: 30px;
    margin-bottom: 0;
    strong {
      font-weight: 700;
    }
    em,
    i {
      font-feature-settings: 'liga' on, 'salt' on;
    }
    &:first-child {
      margin-top: 0;
    }
  }

  a {
    color: ${props => props.theme.primary};
    text-decoration: none;
    background-repeat: repeat-x;
    background-size: 2px 0.1em;
    background-position: 0 1.25em;

    &:hover {
      background-image: ${props =>
        `linear-gradient(#fff 50%, ${props => props.theme.primary} 50%)`};
    }
  }

  img {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
  }

  blockquote {
    background-color: #f6f6f6;
    border-left: 0px;
    padding: 1px 30px 30px 35px;
    margin: 35px 0 0 0;
    color: #656565;
    letter-spacing: 0;
    text-transform: none;
    font: 400 20px/33px ${props => props.theme.sansSerifFontFamily};

    p::before {
      content: '\\201C';
      font-family: Georgia, serif;
      font-weight: bold;
      font-size: 59px;
      color: ${props => props.theme.primary};
      position: absolute;
      margin-left: -28px;
      margin-top: -10px;
    }
  }

  ul,
  ol {
    padding-left: 10px;

    ${media.tablet`
      padding-left: 20px;
    `};
  }

  li {
    margin-left: 30px;
    margin-bottom: 14px;
  }

  ol {
    list-style: none;
    counter-reset: li;
    margin-top: 30px;

    > li {
      counter-increment: li;
      margin-bottom: 5px;

      &::before {
        content: counter(li);
        text-align: center;
        position: absolute;
        margin-left: -40px;
        font: 700 30px/36px ${props => props.theme.sansSerifFontFamily};
        color: ${props => props.theme.primary};
        letter-spacing: -0.003em;
        text-transform: none;
        margin-top: -2px;
      }
    }
  }

  ul {
    list-style: none;
    margin-top: 30px;
    > li::before {
      content: '•';
      color: ${props => props.theme.primary};
      font-size: 30px;
      position: absolute;
      margin-top: -8px;
      margin-left: -25px;
    }
  }
`;

export default ({ content, ...props}) =>
  <Body {...props} dangerouslySetInnerHTML={{ __html: content }} />
