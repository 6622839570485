import React from 'react';
import ContentWrapper from '../../elements/ContentWrapper';
import Title from '../../elements/Title';
import getSections from '../../utils/sections';
import SectionWrapper from '../../elements/SectionWrapper';

export default ({ url, page }) => {
  const sections = getSections(page.sections, url, page, true);
  return <ContentWrapper header={true}>
    {page && page.displayTitle !== false && <SectionWrapper>
      <Title>{page.title}</Title>
    </SectionWrapper>}
    {sections}
  </ContentWrapper>;
};
