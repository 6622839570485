import React from 'react';
import styled from 'styled-components';
import { media } from '../utils/styles';

const Title = styled.h1`
  font-family: ${props => props.theme.headerFontFamily};
  font-size: 36px;
  margin-left: -2.63px;
  line-height: 1.04;
  letter-spacing: -0.015em;
  font-weight: 600;
  padding-top: 5px !important;
  color: ${props => props.theme.primary};
  color: rgba(0, 0, 0, 0.84);
  color: #656565;
  letter-spacing: 0;
  text-transform: capitalize;
  padding: 0 0 5px 0;
  margin: 10px 0 20px;
  margin-top: 60px;

  ${media.tablet`
    font-size: 38px;
    margin-top: 100px;
  `} ${media.desktop`
    font-size: 42px;
    margin-top: 110px;
  `};
`;

export default ({ children, className }) => (
  <Title className={className}>{children}</Title>
);
