import React from 'react';
import styled from 'styled-components';
import Carousel from 'nuka-carousel';
import Arrow from '../../images/arrow.svg';

const Container = styled.section`
    background-color: #F8F9FC;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
`;

const Title = styled.h2`
    font-family: 'proxima-soft',Helvetica,Arial,sans-serif;
    font-weight: 300;
    max-width: 480px;
    letter-spacing: 0.5px;
    margin: 0;
    font-size: 40px;
    text-align: center;
    color: #454D57;
    margin-bottom: 20px;
    white-space: pre-wrap;
    margin: 40px 0 20px 0;
    line-height: 1.17;

    @media (max-width: 940px) {
        font-size: 28px;
        max-width: 380px;
        padding: 0 20px;
    }
`;

const Slide = styled.div`
    font-family: 'proxima-soft',Helvetica,Arial,sans-serif;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    overflow: hidden;
    margin: 0 10px;
    height: 305px;
`;

const SliderWrapper = styled.div`
    display: flex;
    width: 100%;
    padding: 0 50px 80px 50px;
    max-width: 1170px;

    & .slider-list {
        /* min-height: 350px !important; */
    }

    & .slider-frame {
        padding-bottom: 3px !important;
    }

    & .slider-control-bottomcenter {
        bottom: -45px !important;
    }

    @media (max-width: 730px) {
        padding: 0 15px 30px 15px;
    }
`;

const AvatarBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #EDF0F9;
    padding: 20px 0;
`;

const Avatar = styled.div`
    width: 80px;
    height: 80px;
    background-image: url(${props => props.src});
    background-size: cover;
    border-radius: 50%;
`;

const Name = styled.div`
    font-size: 16px;
    text-transform: uppercase;
    color: #00A0E1;
    margin-top: 10px;
    letter-spacing: 0.5px;
`;

const Text = styled.div`
    background-color: white;
    font-size: 14px;
    padding: 15px 20px;
    line-height: 1.5;
    min-height: 150px;
    color: #454D57;
`;

const LeftButton = styled.div`
    width: 23px;
    height: 37px;
    background-image: url(${Arrow});
    position: relative;
    cursor: pointer;
    left: -30px;
    top: -22px;

    &.disabled {
        cursor: default;
        opacity: 0.5;
    }

    @media all and (max-width: 730px) {
        display: none;
    }
`;

const RightButton = styled(LeftButton)`
    transform: rotate(180deg);
    left: 30px;
`;

const IndicatorArray = styled.div`
    display: flex;
`;

const Indicator = styled.div`
    background-color: #EA3154;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    opacity: 0.5;
    margin: 0 5px;
    cursor: pointer;

    &:hover {
        opacity: 0.75;
    }

    &.current {
        opacity: 1;
    }

`;

const countIndicators = ({ slideCount, slidesToShow }) => { 
    if (slideCount % slidesToShow === 0) {
        return slideCount / slidesToShow
    }

    return Math.floor(slideCount / slidesToShow) + 1
}

const indicatorClassName = ({ currentSlide, index, slidesToScroll, slideCount, slidesToShow }) => {
    if (slideCount % slidesToShow === 0) {
        return currentSlide / slidesToScroll === index && 'current'
    }

    return Math.ceil(currentSlide / slidesToScroll) === index && 'current'
} 

const renderLeftButton = ({ previousSlide, currentSlide }) => <LeftButton className={!currentSlide && 'disabled'} onClick={previousSlide} />;

const renderRightButton = ({ nextSlide, currentSlide, slideCount, slidesToShow }) => {
    let cn = '';

    if (slideCount % slidesToShow === 0) {
        if (currentSlide >= (slideCount / slidesToShow)) {
            cn = 'disabled';
        }
    } else {
        if (currentSlide >= Math.ceil(slideCount / slidesToShow)) {
            cn = 'disabled'
        }
    }

    return <RightButton className={cn} onClick={nextSlide} />
};

const renderIndicator = ({ currentSlide, slideCount, goToSlide, slidesToShow, slidesToScroll }) => {
    return (
        <IndicatorArray>
            {Array.apply(null, Array(countIndicators({ slideCount, slidesToShow }))).map((item, index) => (
                <Indicator
                    key={`key-${index}`}
                    onClick={() => goToSlide(index * slidesToScroll)}
                    className={`${indicatorClassName({ currentSlide, index, slidesToScroll, slideCount, slidesToShow })}`}
                />
            ))}
        </IndicatorArray>
    )
};

const renderTestimonial = (item, index) => (
    <Slide key={item.id}>
        <AvatarBox>
            <Avatar src={item.avatar.fixed.src} />
            <Name>{item.avatar.title}</Name>
        </AvatarBox>
        <Text>
            {item.testimonial.testimonial}
        </Text>
    </Slide>
)

export default ({ slidesToShow, section: { title, testimonials = [] } }) => (
    <Container>
        <Title>{title}</Title>
        <SliderWrapper>
            <Carousel
                slidesToScroll={slidesToShow}
                renderCenterLeftControls={renderLeftButton}
                renderCenterRightControls={renderRightButton}
                renderBottomCenterControls={(centerControlsProps) => renderIndicator({ ...centerControlsProps, slidesToShow })}
                slidesToShow={slidesToShow}
                heightMode={'max'}
            >
                {testimonials.map(renderTestimonial)}
            </Carousel>
        </SliderWrapper>
    </Container>
)
