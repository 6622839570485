// What <Link> should always be
import React from 'react';
import { Link } from 'gatsby';

export default ({ to, children, ...props }) => {
  if (!to) return <span {...props}>{children}</span>;

  // It is intended to be an external link
  if (/^https?:\/\//.test(to))
    return (
      <a href={to} {...props}>
        {children}
      </a>
    );
  return (
    <Link to={to} {...props}>
      {children}
    </Link>
  );
};
